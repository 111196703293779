import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby"
import Lightbox from "../components/Lightbox";

let setVideoTimeout;

export default function Carrousel({pageType, isVisibleMore}) {
  const [data, setData] = useState([]);

  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const [open, setOpen] = useState(false);
  const [isHover, setIsHover] = useState(null);

  const [currentItem, setCurrentItem] = useState(null); // Lightbox

  const [loadedVideo, loadVideo] = useState(null);

  const [heightVideo, setHeightVideo] = useState(250);
  const videoBox = useRef(null);

  const handleMouseEnter = (videoId) => {
    setIsHover(videoId)
  };
 
 const handleMouseLeave = () => {
  setIsHover(null)
 };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      const videoId = params.get("video");

      if (videoId) {
        const foundMovie = data.find((movie) => movie._id === videoId);
        setCurrentItem(foundMovie);
        setOpen(true);
      }
    }
  }, [])


  useEffect(() => {
    // ${pageType}
    axios
      .get(`https://api.whitebrim.co/api/model/6351989605e277b6eaf1a05b/craig`)
      .then(response => response.data)
      .then(data => {
        data.results = data.results.sort((a, b) => a.ordering.global - b.ordering.global);

        data.results.forEach(leResult => {
          if (leResult.thumbnail && leResult.thumbnail.edition_options && leResult.thumbnail.edition_options.crop_options) {
            let myEditOption = leResult.thumbnail.edition_options;
            let post_edition_string = "&rect=" + Math.floor(myEditOption.crop_options.x) + "," + Math.floor(myEditOption.crop_options.y) + "," + Math.floor(myEditOption.crop_options.width) + "," + Math.floor(myEditOption.crop_options.height) + "&fit=crop";
            leResult.thumbnail.url = leResult.thumbnail.url.trim() + "?fm=jpg&w=854&h=480" + post_edition_string;
          } else if (leResult.thumbnail && leResult.thumbnail.url) {
            leResult.thumbnail.url = leResult.thumbnail.url.trim() + "?fm=jpg&w=854&h=480";
          } else {
            return null;
          }
        })

        setData(data.results.filter(el => { 
          if(pageType === 'development') return el.development
          if(pageType === '') return !el.development
        }))
      });
  }, [isVisibleMore]);

  const setModal = (status) => {
    setIsHover(null)
    setOpen(status)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-0"
    // style={{ zIndex: 1, position: 'relative'}}
    >
        {
          data && data.map((sliderNo, index) => (
          <div className="wrapper"
            key={`${sliderNo._id}`}
            onMouseEnter = {(ev) => handleMouseEnter(index)}
            onMouseLeave = {(ev) => handleMouseLeave(index, ev)}
            onClick={() => {
              window.history.pushState({}, "", `?video=${sliderNo._id}`)
              setOpen(true);
              setCurrentItem(sliderNo);
            }}
          >
              <div className="w-full md:w-auto slide">
                  <img
                    ref={videoBox}
                    src={sliderNo.thumbnail?.url}
                    alt="firstvideoframe"
                    className={(index === isHover) ? "selected" : ""}
                  />
                  <div className="wrapper-text">
                    <p className="text-center uppercase px-5"
                    style={{ display: (index === isHover) ? 'block' : 'none'}}
                    ></p>
                  </div>
              </div>
        </div>
        )
      )}
      <Lightbox
        type={pageType}
        open={open}
        setOpen={setModal}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  )
}