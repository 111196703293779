import React, { useEffect, useState  } from "react"
import { Link } from "gatsby"
import Slider from "./Slider"

import { Parallax } from 'react-scroll-parallax';

import logoShape from "../assets/logo_shape.png"

import "../assets/css/menu.css"

const Advertising = () => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const [isVisibleMore, setIsVisibleMore] = useState(false);

  useEffect(() => {
    
  }, [])

  return (
    <>
      <section className="w-screen bg-black pt-60">
        <h1 className="main-title text-center uppercase text-white pb-10">Selected Work</h1>
      </section>
     
      <div className="bg-black w-screen overflow-x-hidden" style={{
            zIndex: 1,
            position: 'relative',
      }}>
        <Slider pageType={''}/>
      </div>

      {/* PARALAX RIGHT BOTTOM */}
      <Parallax speed={50} shouldAlwaysCompleteAnimation={true} style={{position: 'relative', top: -1500, zIndex: 1}}>
        <img
          className="hidden md:block"
          style={{ position: 'absolute', left: 'calc(100% - 100px)', zIndex: 10 }}
          src={logoShape}
          alt="lolflow"
          />
      </Parallax>
      <section className="w-screen bg-black pt-60">
        <h1 className="main-title text-center uppercase text-white p-10">In Development</h1>
      </section>
      <div className="bg-black w-screen overflow-x-hidden" style={{
            zIndex: 1,
            position: 'relative',
      }}>
        <Slider pageType={'development'}/>
      </div>
    </>
  )
}

export default Advertising
