import React, { useState, useRef, useEffect } from "react"
import { Parallax } from 'react-scroll-parallax';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faImdb,
} from '@fortawesome/free-brands-svg-icons'

import "../assets/css/menu.css"
import "../assets/css/global.css"

import Layout from "../components/Layout"
import Advertising from "../components/Advertising"

import logoShape from "../assets/logo_shape.png"
import logoShapeLL from "../assets/logo_shape_ll.png"
import bannerVid from "../assets/cv_animation.mp4"
import SEO from "../components/SEO"


const IndexPage = () => { 
  const [scrollPosition, setScrollPosition] = useState(0);
  const [zoomLogo, setZoomLogo] = useState(1);
  const [positionFromTop, setPositionFromTop] = useState(0);
  const [opacityFromTop, setOpacityFromTop] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      const fullHeight = window.innerHeight
      const halfHeight = fullHeight / 2;
      const positionTop = halfHeight - 160 - (window.pageYOffset / fullHeight) * (halfHeight - 200);

      if(window.pageYOffset < fullHeight) {
        setPositionFromTop(positionTop);
        setZoomLogo(1 - (window.pageYOffset / fullHeight) * 0.8 );
        setOpacityFromTop( (window.pageYOffset / fullHeight) * 0.8 );
      } else {
        setPositionFromTop(40);
        setZoomLogo(0.2009);
        setOpacityFromTop(1);
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    
    <Layout>
      <div className="bg-black " style={{overflowY : 'hidden', overflowX : 'hidden'}}>
        {/* <img 
          src={logo}
          style={{ position: 'fixed', opacity: `${opacityFromTop}`, top: `${positionFromTop}px`, left: `calc(50% - ${zoomLogo*147}px)`, zIndex: 2, height: `${zoomLogo*400}px` }}
        ></img> */}

      <section className='h-screen'>
      <video
          playsInline
          muted
          autoPlay
          loop
          className={`intro-section`}
          style={{  zIndex: 1, display: 'block', position: 'relative', 'objectFit': 'cover' }}
        >
          <source src={bannerVid} type='video/mp4' />
        </video>

      </section>

        {/* PARALAX LEFT TOP */}
        <Parallax speed={-100} style={{ display: 'block', zIndex: -10 }}>
          <img
            className="hidden md:block"
            style={{ position: 'absolute', right: 'calc(100% - 400px)', top: 100 }}
            src={logoShape}
            alt="Workflow"
          />
        </Parallax>

        {/* PARALAX RIGHT TOP */}
        <Parallax speed={50}>
          <img
            className="prx-r-t"
            style={{ zIndex: 10 }}
            src={logoShape}
            alt="Workflow"
            />
        </Parallax>

      <Advertising  />

      <section className="w-screen py-60" >
        <div className="text-films-1 container mx-auto">
          <div className="grid md:grid-cols-2 place-items-end mx-auto">
            <div className="sm:col-span-1 md:col-span-1 md:col-start-2">
              {/* <h1 className="text-white uppercase pb-10 text-center md:text-start">A title</h1> */}
              <p className="max-w-4xl text-5xl md:text-5xl leading-normal tracking-widest text-center md:text-start text-white">
              Founded by award-winning film-maker and artist Craig Viveiros, 5ivefs is a collaborative community project committed to uniting artists from around the globe in the creation of innovative and original content across various mediums. Our mission is to amplify the voices of artists striving to inspire unity, harmony, and togetherness through their work. Craig is also a partner in Lisbon-based production / service company <a style={{ color: "#a1a1c1"}} href="https://agfilms.tv">AG Films</a>. Craig is represented by <a style={{ color: "#a1a1c1"}} href="https://www.unitedagents.co.uk/">United Agents</a> and <a style={{ color: "#a1a1c1"}} href="https://www.wmeagency.com/">WME</a>.
              </p>
              <ul className="social">
                 <li>
                  <a href="https://www.instagram.com/5ivefs/" title="">
                  <FontAwesomeIcon icon={faInstagram} size="2x"/>
                  </a>
                </li>
                {/*
                <li>
                  <a href="https://www.facebook.com/" title="">
                  <FontAwesomeIcon icon={faYoutube} size="2x"/>
                  </a>
                </li> */}
                <li>
                  <a href="https://www.imdb.com/name/nm2244239/?ref_=ttfc_fc_dr1" title="">
                  <FontAwesomeIcon icon={faImdb} size="2x"/>
                  </a>
                </li>
                {/* <li>
                  <a href="https://www.linkedin.com/in/craig-viveiros-82141333/?originalSubdomain=uk" title="">
                  <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                  </a>
                </li> */}
              </ul>

            </div>
          </div>
        </div>
      </section>

     
      {/* LEFT BOTTOM PARALLAX */}
      <Parallax speed={-80}>
        <img
          className="prx-l-b"
          src={logoShapeLL}
          alt="Workflow"
        />
      </Parallax>


      {/* <footer className="bg-[#a1a1a1] p-2" style={{ zIndex: 10, position: 'relative'}}>
        <div className="w-full mx-auto container md:p-6 p-4">
          <p className="text-gray-600 text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
      </footer> */}

      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)